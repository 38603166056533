.error_top{
    display: flex;
    height: 85%;
}

.error_bottom{
   
    height: 15%;
}
.display_flex{
    display: flex;
    align-items: center;
}

.main_error_div {
    margin: 0 auto;
}
.error_img img{
    margin-left: auto;
    margin-right:15px;
    margin-top: 32px;
}
.error_top .row {
    width: 100% !important;
    margin: 0px !important;
}
.four_not_four{
    font-size: 160px;
    font-weight: 700;
    color: #2B5EC2;
    line-height: 157px;
}
.line{
    width: 100%;
    height: 1px;
    background-color:#2B5EC2;
}
.oops_div {
    color:#262525;
    font-size: 15px;
    font-weight: 600;
    margin-top:10px;
}
.not_available{
    color: #A6A6A6;
    font-size: 11px;
    font-weight: lighter;
    letter-spacing: 0.5px;
}
.error_table{
    display: table;
}
.error_table_cell{
    display: table-cell;
    vertical-align: middle;
}
.error_term_condition {
    font-size: 9px;
    color: #9F9F9E;
    text-align: center;
}
.error_login_copy_right div {
    color: #141414;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
}