.prof-detail-postWrapper input{
    padding: 6px 0px;
}
.wiki-div {
    margin-top: 17px;
}
.wiki-div h1 {
    font-size: 1.5rem;
}
.wiki-div h2 {
    font-size: 1rem;
}
.wiki-div h3 {
    font-size: 0.75rem;
}