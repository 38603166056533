.ass-sb-button .button{
    margin: 0;
    width: 100%;
}
.range_slider {
  width: 100%;
  margin-bottom: 20px;
  overflow: visible;
}

.rangeslider-horizontal {
  height: 6px;
  border-radius: 20px;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 16px;
  height: 16px;
  border-radius: 33px;
  top: 50%;
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

.rangeslider__labels .rangeslider__label-item {
  font-size: 16px !important;
}

/* slider options */
.slider_options .rangeslider__labels .rangeslider__label-item {
  display: inline-table;
}
/* slider options ends */
.quest-slider.rangeslider .rangeslider__handle-tooltip{
    position: relative;
    width: auto;
    min-width: 50px;
    height: auto;
    padding: 0em .8em .5em .8em;
    top: 100%;
    transform: translate(-50%, -100%);
    
}
.quest-slider.rangeslider .rangeslider__handle-tooltip span{
  font-size: 14px;
}
.questions-file-preview{
  color: #339379;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.questions-file-delete{
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}
.scroll-qstn-txt-area{
  overflow-y: auto !important;
}

.quest-slider .ant-drawer-content-wrapper {
	 width: 50vw !important;
	 min-width: 300px;
	 max-width: 400px;
}
.ant-drawer-body .slider-option-wrap{
    margin-bottom: 0px;
}
 @media screen and (max-width: 640px) {
	 .quest-slider .ant-drawer-content-wrapper {
		 width: 100% !important;
	}
}
 .quest-slider .ant-drawer-body {
     padding: 0;
     height: calc(100% - 65px);
     overflow: hidden;
}
.quest-slider .ant-drawer-content{
    overflow: hidden;
}
 .quest-slider .drawer-body-content {
	 position: relative;
	 display: flex;
	 flex-direction: row-reverse;
	 justify-content: center;
	 align-items: center;
	 min-height: 450px;
     height: calc(100% - 60px);
     padding: 16px 24px;
}
 .quest-slider .drawer-body-content > div {
     height: 100%;
}
 .quest-slider .quest-smiley {
	 position: absolute;
     right: 24px;
     height: calc(100% - 24px) !important;
}
 .quest-slider .rangeslider__handle-label {
     text-align: center;
     font-weight: 500 !important;
}
 .quest-slider .ant-drawer-body .quest-smiley svg {
	 width: 5.2em;
	 height: 5.2em;
}
 .drawer-slider {
	 margin: 0;
	 min-width: 3em;
	 border-radius: 7px;
	 margin-left: 1em;
}
 .drawer-slider.flat-slider.rangeslider-vertical {
	 background: #339379;
}
 .drawer-slider.flat-slider.rangeslider-vertical .rangeslider__labels {
	 width: 1em;
	 left: 100%;
	 margin-left: auto;
	 z-index: 1;
}
 .drawer-slider:not(.flat-slider) {
	 background: linear-gradient(180deg, #1a9c27 0%, #1de331 21.88%, #ffc023 41.1%, #fe8616 60.94%, #fe4f0f 79.69%, #ff0606 99.48%);
}
 .drawer-slider:not(.flat-slider).green-bottom {
	 background: linear-gradient(0deg, #1a9c27 0%, #1de331 21.88%, #ffc023 41.1%, #fe8616 60.94%, #fe4f0f 79.69%, #ff0606 99.48%);
}
 .drawer-slider.rangeslider-vertical {
	 display: flex;
}
 .drawer-slider.rangeslider-vertical .rangeslider__labels {
	 width: 3em;
	 left: -100%;
	 margin-left: -22px;
	 z-index: 1;
}
 .drawer-slider.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
     transform: translate3d(0%, 0, 0);
     font-weight: 600;
}
 .drawer-slider.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
	 width: 0;
	 height: 0;
}
 .drawer-slider.rangeslider-vertical .rangeslider__handle {
	 width: 90%;
	 left: 50%;
	 height: 1.5em;
	 transform: translateX(-50%);
	 border-radius: 0.3em;
}
 .drawer-slider.rangeslider-vertical .rangeslider__fill {
	 display: none;
	 border-radius: 7px;
}
 .modal-btn {
	 border: 1px solid #339379;
	 padding: 0.5em 0.9em;
	 transition: all 0.3s ease-in-out;
}
 .modal-btn:hover {
	 background: #339379;
	 color: #fff;
}
 .slider-btn-wrap {
	 position: absolute;
	 left: 24px;
	 display: flex;
	 flex-direction: column;
     justify-content: space-between;
     height: calc(100% - 24px) !important;
}
 .slider-btn-wrap button {
	 width: 2.6em;
	 height: 2.6em;
	 min-height: 40px;
	 min-width: 40px;
     background: #f3f2f2 !important;
     border: none;
}
.circle{
    border-radius: 50%;
}
 .slider-btn-wrap button svg {
	 width: 3.3em !important;
	 height: 3.3em;
	 max-width: 20px;
	 max-height: 100% !important;
}
 .slider-btn-wrap button:not(:hover) path {
	 stroke: #585859;
}
 .quest-sliderheader {
	 min-height: 2em;
}
 .quest-sliderheader span {
	 flex-grow: 1;
	 max-width: 90%;
	 opacity: 0;
	 pointer-events: none;
	 white-space: nowrap;
	 overflow: hidden;
	 text-overflow: ellipsis;
}
 .quest-sliderheader button {
	 background: transparent !important;
     max-height: 30px;
     border: none;
}
 .quest-sliderheader svg {
	 height: 18px;
}
 .quest-sliderheader svg * {
	 fill: #585859;
}
 .quest-sliderheader svg.close_svg {
	 height: 25px;
}
 .optionwrap .btn-group input {
	 background-color: #f3f3f3;
	 border: none !important;
     max-width: 200px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
 .optionwrap .btn-group button {
    min-width: 100px;
    border-radius: 5px;
    border: 1px solid;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background: #fff;
}

.slider-arrow {
    background-image: url(/images/slider-arrow.png);
    width: 19px;
    height: 20px;
    background-size: contain;
    position: absolute;
    top: 0;
    left: -31px;
    z-index: 5;
}
 @media only screen and (max-width: 1540px) {
	 .quest-slider .ant-drawer-body .quest-smiley .kado-svg svg {
		 width: 4.6em;
		 height: 4.6em;
	}
	 .drawer-slider {
		 font-size: 14px;
	}
	 .slider-arrow {
		 width: 16px;
		 height: 17px;
	}
}
 @media only screen and (max-width: 1280px) {
	 .quest-slider .ant-drawer-body .quest-smiley svg {
		 width: 4em;
		 height: 4em;
	}
	 .drawer-slider {
		 font-size: 13px;
	}
}

.quest-slider .drawer-footer{
    padding: 10px 24px;
    border-top: 1px solid #f0f0f0;
    height: 60px;
}
@media only screen and (max-width: 1470px) {
	.quest-slider .ant-drawer-body .quest-smiley svg {
		width: 4.5em;
		height: 4.5em;
   }
	.drawer-slider {
		font-size: 14px;
   }
}

.quest-longest-label-slider{
	margin-bottom: 5em !important;
}