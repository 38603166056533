.kado-checkbox{
    background-color: transparent !important;
}
.kado-checkbox.block .ant-checkbox-group-item {
    /* display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; */
    margin-bottom: 8px;
}
.ant-checkbox-wrapper-checked span{
    color: #44a48a !important;
}