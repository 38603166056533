@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
body, html{
    font-family: 'Roboto', sans-serif;
}

#root {
    overflow: hidden;
}
textarea, input, button, div, span 
{ outline: none !important; }

a, a:not([href]):not([tabindex]) {
    text-decoration: none;
    cursor: pointer;
}

.text-center {
	text-align: center;
}

.noPadding {
	padding: 0px !important; 
}

.autoscroll {
	overflow: auto;
}
.noscroll{
    overflow: hidden;
}

.height100 {
	height: 100%
}

.flex-colum {
	display: flex
}
.flex-colum div {
	flex-grow: 1;
}

strong, b {
	font-weight: 700;
}


/*.    HOVER.    */
.button:hover,
button:hover{
    opacity: 0.8;
    transition: all .3s
}
.button:active,
button:active{
    opacity: 7;
    transition: all .3s
}

/* Align */
.text-center {
    text-align: center;
}
.capital-first{
    text-transform: lowercase;
}
.capital-first:first-letter {
    text-transform: uppercase; 
}