@keyframes jump {
    0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
    40%  {transform: translate3d(0,30%,0) scale3d(.7,1.5,1);}
    100% {transform: translate3d(0,100%,0) scale3d(1.5,.7,1);}
}
.cl-container {
    text-align: center;
}
.cl-logo {
    position: absolute;
    bottom: 50%;
    height: 50px;
}
.jump {
    /*transform-origin: 50% 50%;*/
    position: absolute;
    bottom: 20%;
    height: 50px;
    animation: jump .5s linear alternate infinite;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .cl-container {
        padding: 0 50%;
    }   
}