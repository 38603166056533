.ant-radio-wrapper.radio-block{
	display: block;
}
.kadoRadio.ant-radio-group .ant-radio-wrapper {
	font-family: Roboto,HelveticaNeue,Arial,sans-serif;
}
.sbs-header .kadoRadio.ant-radio-group .ant-radio-wrapper {
	font-family: 'Lato' ;
	font-weight: 800;
}
/* font color */
.kadoRadio.inverted .ant-radio-wrapper {
    color: #FFF;
}
.kadoRadio.inverted .orange-inverted.ant-radio-wrapper-checked {
	background-color: #edb00a;
	border-radius: 5px;
}
.main-container .kadoRadio.inverted span {
	color: black;
	/* font-weight: bold; */
}
.kadoRadio.inverted .ant-radio-wrapper-checked span {
	color: white;
	font-weight: bold;
}
.kadoRadio.inverted .grey-inverted.ant-radio-wrapper-checked {
	background-color: #9e9e9e;
	border-radius: 5px;
}
.kadoRadio.inverted .yellow-inverted.ant-radio-wrapper-checked {
	background-color: #F6C913;
	border-radius: 5px;
}
.kadoRadio.inverted .red-inverted.ant-radio-wrapper-checked {
	background-color: #f34482;
	border-radius: 5px;
}
.kadoRadio.inverted .green-inverted.ant-radio-wrapper-checked {
	background-color: #51AF96;
	border-radius: 5px;
}
/* contianer-bg */
.kadoRadio{
    background-color: transparent !important;
}

/* round dot at center */
.kadoRadio.green.inverted .ant-radio-inner:after{
	background-color: #ffffff;
}

/* handles animation */
.kadoRadio.green.inverted .ant-radio-checked:after {
	border: 1px solid #ffffff;
}
.kadoRadio.green.inverted .ant-radio-checked .ant-radio-inner {
	border-color: #ffffff;
}


/*
grey
green
yellow
orange
red

*/

.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .grey-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner{
	border-color: #9e9e9e
}
.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .yellow-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner{
	border-color: #F6C913
}
.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .orange-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner{
	border-color: #edb00a
}
.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .red-inverted.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inne{
	border-color: #ff4a52;
}

.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.grey .ant-radio-checked:after,
.kadoRadio .grey-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #9e9e9e;
}

.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.yellow .ant-radio-checked:after,
.kadoRadio .yellow-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #F6C913;
}

.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.orange .ant-radio-checked:after,
.kadoRadio .orange-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #edb00a;
}

.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner:after,
.kadoRadio.red .ant-radio-checked:after,
.kadoRadio .red-inverted.ant-radio-wrapper .ant-radio-checked:after {
	background-color: #ff4a52;
}

.kadoRadio .grey-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .green-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .yellow-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .orange-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner,
.kadoRadio .red-inverted.ant-radio-wrapper  .ant-radio-checked .ant-radio-inner {
	background-color: #FFF;	
}

.kadoRadio label{
	/* min-width: 100%; */
	max-width: fit-content;
	padding-left: 4px;
}
.radio-block.ant-radio-wrapper span:not(.ant-radio){
	max-width: 100%;
	word-break: break-word;
	white-space: pre-line;
}
.selected-option span{
	color: #44a48a !important;
}
