.md-form {
	margin-bottom: 0px;
}
.form-group {
	margin-bottom: 10px;
}

/*input,*/
/*.form-group input,*/
.md-form input,
.form-control {
	margin-top: 0px;
	margin-bottom: 0px;
	line-height: 1;
	padding: 6px 0px;

}
