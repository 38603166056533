.mam-login-container{
    height: 90%;   
    align-items: center;
}
.login-form{
    border-radius: 12px;
    background: #fff;
    min-width: 300px;
    max-width: 18%;
    margin: 0 auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    min-height: 82%;
}
.login-footer,
.login-header{
    min-height: 18%;
    position: relative;
    padding: 20px 40px;
}
.login-form-container{
    min-height: 64%;
    padding-top: 18vh;
}
.mamebe-logo{
    background-image: url(/images/mamebe.png);
    height: 230px;
    width: 230px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.asses-input{
    width: 220px;
    margin: 0 auto;
    position: relative;
    text-align: center;
}
.asses-input label{
    font-size: 14px;
    font-weight: 500;
    color: #6b6b6b;
    position:absolute;
    width: 100%;
    letter-spacing: 1.5px;
    pointer-events:none;
    transition:0.5s ease all; 
    -moz-transition:0.5s ease all; 
    -webkit-transition:0.5s ease all;
    top:0px;
    left: 0;
}
.asses-input input{
    border-radius: 0;
    font-size: 16px;
    display: inline-block;
}
.asses-input input:focus ~ label,
.asses-input input:valid ~ label {
    top:-20px;
    font-size: 13px;
    color: #51af96 !important;
    letter-spacing: 1px;
    font-weight: 600;   
}
.language-text{
    display: none;
}
.Select-menu-outer .language-text{
    display: inline;
    font-family: Roboto;
}
.login-form-container button{
    background-color: #51af96;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    min-width: 150px;
    font-weight: 500;
    font-size: 14px;
    max-height: 45px;
    font-family: inherit;
    letter-spacing: 1px;

}
.login-form-container button:hover{
    background-color: #44a48a;
    opacity: 1;
}
.navbar .lang-selector,
.login-header .lang-selector{
    float: right;
    width: 160px;
}
.input-asses-failed{
    padding: 15px;
    text-align: center;
    font-size: 12px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-top: 5px solid #e53935;
}

.hr-danger{
    position: relative;
    border-bottom: 5px solid red;
    top:-1px;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 568px) and (-webkit-min-device-pixel-ratio: 2){
    .login-form-container{
        margin-top: 15px;
    }
    .login-footer{
        padding: 0;
    }
    .print-btn, .pdf-dwn-btn{
        width: 25px;
        padding: 0px 5px;
    }
    .print-pdf-div{
        display: none;
    }
}

/* for chrome */
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
    .login-form-container {
        padding-top: 8vh;
    }
    .ass-button.ass-nav-btn{
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .ass-nav-btn-right{
        float: right;
    }
  }
.ass-qst-options .rangeslider-horizontal ul.rangeslider__labels > li:nth-child(1),
.ases-single-options .rangeslider-horizontal ul.rangeslider__labels > li:nth-child(1){
    transform: translate3d(-90%, 0, 0);
    text-align: right;
    white-space: pre;
}
.ass-qst-options .rangeslider-horizontal ul.rangeslider__labels > li:last-child,
.ases-single-options .rangeslider-horizontal ul.rangeslider__labels > li:last-child{
    transform: translate3d(-10%, 0, 0);
    text-align: left;
}