.main-container {
  border: none;
  box-shadow: none;
  overflow: hidden;
}
.dashboard-container {
  height: 100%;
  overflow: hidden;
  border: none;
  box-shadow: none;
}
.quick-container {
  min-width: 250px;
  max-width: 30%;
  padding: 0px;
}

.diary-container {
  padding-right: 0px;
}
.textCenter {
  text-align: center;
}
@media (min-width: 576px) {
  .dashboard-container {
    min-width: 100%;
  }
}
.mc-content-div {
  overflow: hidden;
  overflow-y: auto;
  height: 100vh;
}
.mc-bottom-btn {
  padding: 0px 10px 5px 0px;
}

.offline_toast {
  position: absolute;
  top: 12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #777474;
  color: #fff;
  z-index: 1002;
  padding: 10px 50px;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.26);
}
.offline_toast img {
  margin-right: 10px;
  width: 15px;
  vertical-align: text-bottom;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dashboard-container {
    left: 0px !important;
  }
  .sb_toogler{
    width: 40px;
  }
  @media (min-width: 576px) {
    .dashboard-container {
      padding-left: 0px !important;
    }
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-con-wrapper{
  /* position: fixed;
  top: 50px; */
}
.fullscreen-btn{
    min-width: 2.8rem;
    min-height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}
@media (max-width:1366px){
    .fullscreen-btn{
        top:64px;
    }
}
@-moz-document url-prefix() {
    .fullscreen-btn{
        top:74px;
    }
}
.login-mc + .fullscreen-btn{
    display: none;
}
#dashboard .nav-actions{
    padding-right: calc(1rem + 38px);  /*58-20px of navbar padding*/
}
.mc-content-wrapper{
    /*padding-left: calc(1rem + 58px);*/ /*43px of logo + 1rem */
    overflow: hidden !important;
}
.page-content > div{
    padding-left: calc(1rem + 58px);
    padding-right: calc(1rem + 58px);
}
.mc-content-wrapper .page-content .header {
    margin-left: calc(1rem + 58px);
    margin-right: calc(1rem + 58px);
    padding: 0;
}
.nd_p-l{
    padding-left: calc(1rem + 58px);
}
.nd_p-r{
    padding-right: calc(1rem + 58px);
}
.nd_m-r{
    margin-right: calc(1rem + 58px);
}
.nd_m-l{
    margin-right: calc(1rem + 58px);
}
.page-footer .button.btn-with-brand{
    min-width: 100px !important;
}
@media (max-width: 1200px){
    .navbar,
   .page-content > div:not(.header){
    padding-left: calc(1rem + 18px) !important;
    padding-right: calc(1rem + 18px) !important;
    } 
    .mc-content-wrapper .page-content .header {
    margin-left: calc(1rem + 18px);
    margin-right: calc(1rem + 18px);
    }
    #dashboard .nav-actions{
        padding-right: calc(0rem + 0px)
    }
}

@media (max-width: 740px){
.navbar,
   .page-content > div:not(.header){
    padding-left: calc(1rem + 0px) !important;
    padding-right: calc(1rem + 0px) !important;
    } 
    .mc-content-wrapper .page-content .header {
    margin-left: calc(1rem + 0px);
    margin-right: calc(1rem + 0px);
    }
    #dashboard .nav-actions{
        padding-right: calc(0rem + 0px)
    }
    .scale-slider{
        min-height: 680px;
    }
}
@media (max-width: 576px){
    .btn-wrap .button{
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    }
}