.navbar {
  min-height: 4rem;
  /* max-height: 4rem; */
  padding: 5px 20px 0px 20px;
  background-color: #fff;
  z-index: 11;
  box-shadow: none;
}
.navbar.nobshadow{
  box-shadow: none;
}
.languageSwitcher {
  width: 80px;
}
.languageSwitcher .Select-menu-outer{
    min-width: 180px;
}

/* for language selector */
.languageSwitcher .Select,
.languageSwitcher .Select.is-open {
  border-bottom: none;
}
.navbar .languageSwitcher .Select .Select-input {
  padding-left: 0px;
}
.navbar .Select{
    height: 2.8rem;
    display: flex;
    align-items: center;
    padding: 0 !important;

}
@media only screen and (max-width: 380px) {
    .languageSwitcher{
        min-width: unset;
        max-width: 50px;
    }
    .navbar .Select-menu-outer {
        max-width: 150px;
        position: fixed;
        top: 3rem;
        right: 0 !important;
        left: 0%;
        min-width: 100%;
    }
}
.mam-login-container .languageSwitcher{
    width: 100%;
    max-width: 300px;
}
.mam-login-container .languageSwitcher .Select-control{
    background: transparent;
}
/* navabr-reminder */
.nb-rem-dropdown {
  border-bottom: 1px solid #ddd;
}
.nb-rem-dropdown:last-child {
  border: none !important;
}
.nb-rem-dropdown:hover {
  background-color: #e8eaea;
}
.navbar-reminder .ant-dropdown-menu-item {
  padding: 0px;
}
.nb-rem-items {
  padding: 6px;
}
.nb-rem-dropdown .btn-group {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 6px 0px 12px 0px;
}
@-moz-document url-prefix() {
  .navbar {
    min-height: 65px;
    max-height: 65px;
    border-bottom-style: solid;
    border-width: 0.1px;
    padding: 20px 10px;
    border-bottom: 0.1px solid #dddcdc;
    background-color: #fff;
    z-index: 20;
    
  }
}
.ant-dropdown-menu.navbar-profile .ant-dropdown-menu-item-active > a {
  color: #fff;
}
.navbar-profile .ant-dropdown-menu-item {
  line-height: 30px;
}

.toast_container {
  border-radius: 4px;
  padding: 8px 15px;
}
.cankado_toast button {
  position: relative;
  top: 6px;
}

.toast_container.success {
  background-color: #51af96;
}

.toast_container.info {
  background-color: #59a3e9;
}

.toast_container.error {
  background-color: #f34482;
}
.toast_container div {
  font-size: 13px;
}
@media (max-width: 425px) {
  .languageSwitcher {
    /* display: none; */
  }
}
@media (max-width: 490px) {
  .navbar {
    padding-left: 6px;
  }
  .navbar-title {
    /* max-width: fit-content;
    min-width: fit-content; */
  }
}
.languageSwitcher .Select-control {
    height: 100%;
    background: #F6F6F6;
    border-radius: 5px;
    padding: 5px;
}
.languageSwitcher .Select.is-focused:not(.is-open) > .Select-control{
    background: #F6F6F6;
}
.languageSwitcher .Select-value-label,
.languageSwitcher .Select-value-label .label,
.languageSwitcher .Select-multi-value-wrapper{
    height: 100%;
    position:absolute;
    width: 100%;
}
.languageSwitcher .Select-value-label img{
  width: 40px;
  height: 40px;
  position: absolute;
  align-items: center;
  top: -4px;
}
.languageSwitcher .Select-arrow-zone{
    padding: 0;
    width: 1em;
}
.languageSwitcher .label,
.languageSwitcher .Select-value-label{
    display: block;
}
.languageSwitcher .label{
    height: 126% !important;
}

.languageSwitcher ::-webkit-scrollbar {
  display: none;
}