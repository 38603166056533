
.reminderPage .heading, .messages .heading {
    margin-top: 17px;
}
.mc-physician .physicianInfo,
.mc-reminder .reminderPage,
.mc-files .file-content,
.mc-messages .messages,
.mc-profile .physicianInfo,
.mc-wiki .wiki-div,
.mc-fandf .physicianInfo,
.mc-diary-reminder .mc-diaryreminder-content .mc-scroll-div{
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    margin-bottom: 50px;
    padding-bottom: 126px;
}

@-moz-document url-prefix() {
    .mc-physician .physicianInfo .mc-scroll-div,
    .mc-reminder .reminderPage .mc-scroll-div,
    .mc-files .file-content .mc-scroll-div,
    .mc-fandf .physicianInfo .mc-scroll-div,
    .mc-profile .physicianInfo .mc-scroll-div{
        padding-bottom: 120px;
    }
    .mc-messages .messages .mc-scroll-div{
        padding-bottom: 180px;
    }
} @media (max-width: 991px){
        .headerContainer .sb_toogler{
            display: none;
        }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .mc-physician .physicianInfo .mc-scroll-div,
    .mc-reminder .reminderPage .mc-scroll-div,
    .mc-files .file-content .mc-scroll-div,
    .mc-fandf .physicianInfo .mc-scroll-div,
    .mc-profile .physicianInfo .mc-scroll-div{
        padding-bottom: 120px;
    }
    .mc-messages .messages .mc-scroll-div{
        padding-bottom: 180px;
    }
}
@supports (-ms-ime-align: auto) {
    .mc-physician .physicianInfo .mc-scroll-div,
    .mc-reminder .reminderPage .mc-scroll-div,
    .mc-files .file-content .mc-scroll-div,
    .mc-fandf .physicianInfo .mc-scroll-div,
    .mc-profile .physicianInfo .mc-scroll-div{
        padding-bottom: 120px;
    }
    .mc-messages .messages .mc-scroll-div{
        padding-bottom: 180px;
    }
}
.diary-reminder-list-div.mc-diaryreminder-content > div > div:nth-child(odd){
    background: #efefef;
}
.rem-lst-mc-date{
    /* display: flex !important;
    justify-content: center;
    align-items: center; */
}
.rem-lst-mc-detail{
    display: inline-flex;
    text-align: left;
    width: 300px;
    justify-content: space-between;
}
.rem-lst-mc-detail > span{
    padding-top: 3px;
}
.diary-reminder-list-entries > div{
    justify-content: space-between;
}
.diary-reminder-list-entries > div > div{
    min-height: 47px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.mc-header {
    min-height: 50px;
    max-height: 50px;
  }
  .headerContainer {
    min-height: 50px;
    /* max-height: 50px; */
    border-bottom: 1px solid #dddcdc;
    justify-content: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* background-color: #dddd; */
  }
  .diaryHeader {
    margin-top: 14px;
  }
  .diaryHeader.diaryHeaderdate {
    max-width: 110px;
    min-width: 110px;
    border-right: 1px solid #f4f4f4;
  }
  .diaryTime {
    min-width: 50px;
    max-width: 50px;
    padding: 5px 0px 0px 0px;
  }
  .diaryBulletBox {
    max-width: 30px;
    padding: 5px 2px 0px 0px;
  }
  .diaryDescBox {
    padding: 5px 0px 0px 0px;
  }
  .diaryActionsBox {
    max-width: 90px;
    text-align: right;
  }
  .diaryActionsBox button {
    margin: 5px;
  }
  
  .diaryLoadMoreBtn {
    display: block;
    width: 100%;
    padding: 50px 0px;
    /*cursor: pointer;*/
    background-color: #fff;
    border: none;
    margin-bottom: 50px;
    font-weight: bold;
    text-transform: uppercase;
    color: #4cb2e1;
    pointer-events: none;
  }
  .diaryLoadMoreBtn:active,
  .diaryLoadMoreBtn.active {
    background-color: #fff;
  }
  .diary-editor .ant-time-picker-input {
    display: block;
  }
  .diary-editor input {
    padding: 6px 1px;
  }
  .sb_toogler {
    cursor: pointer;
    height: 40px;
    float: right;
  }
  .mc-header.mc-title {
    padding: 15px 0px 12px 5px;
  }
  
  .diary-entry-group .ant-slider-mark-text{
      width: 4.5% !important;
      margin-left: -2.25% !important;
  }
  .diary-entry-group .ant-slider-mark {
      left:3px ;
  }

  @media (max-width: 490px) {
        .headerContainer{
            display: block;
        }
   
  }