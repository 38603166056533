/* new */
html, body {
  font-size: 16px; 
}
@media (max-width:1600px){
  html, body {
    font-size: 16px; 
  }
}
@media (max-width:1366px){
  html, body {
    font-size: 14px; 
  }
}
#maincontainer {
  min-width: 300px;
  overflow: hidden;
  height: calc(100% - 4rem);
}
#maincontainer.login-mc{
  min-width: 300px;
}

.page-content {
  font-size: 1rem;
  font-weight: 500;
  height: 100%;
}
#mcPrintOff{
  height: 100vh;
  background: #e0e0e0;
  opacity: .5;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 999;
  pointer-events: none;
  top: 0;
}
.page-content.print-hide .assessmentPrintDiv{
  display: none;
}
.mc-content-wrapper{
  height: 100%;
  background-color: white;
}
.mc-wrapper:not(.login-mc) .mc-content-wrapper > div:first-child{
  min-width: 300px;
}
.qstr-scroll{
    /* padding: 0 20px; */
    /* height: calc(100% - 0px); */
    overflow: visible;
}
.qstr-scroll.overflow-visible{
    overflow: visible;
}
.mc-content-wrapper .header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    /* padding: 0px 20px 0px 20px; */
    min-height: 4rem;
    border-bottom: 1px solid #dcdbdb;
}
.mc-content-wrapper .page-content .header {
    margin-bottom: 20px;
    z-index: 10;
    overflow: auto;
    position: relative;
}
.qstnr-wrapper {
  border: 1px solid #f6f6f6;
  padding: .4em 1.3em;
  cursor: pointer;
  border-radius: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fcfcfc;
  margin-right: 10px;
  height: 2.8rem;
  margin-bottom: 1em;
  font-size: 1rem;
  transition: all .3s linear;
  align-items: center;
}
.ass-tab.selected {
  color: #44a48a;
  border-color: #44a48a;
}
.main-heading,
.mc-content-wrapper .heading {
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #585859;
  display: block;
  font-size: 1.2em;
  background-color: white;
}
.ass-qst-zoomlist > .heading,
.mc-content-wrapper .ass-ovrview-qna .heading{
  /* text-transform: lowercase; */
  color: #222;
  margin-bottom: 1em;
}
.ass-qst-zoomlist > .heading::first-letter,
.mc-content-wrapper .ass-ovrview-qna .heading::first-letter{
  /* text-transform: uppercase; */
}
.mc-content-wrapper .heading p{
  font-size: 1rem;
  margin-bottom: 5px;
}
.qa-option-wrap .rangeslider-horizontal, 
.qa-option-wrap>div:not(.btn-group):not(.rightAligned) {
  width: 97%;
}
.quest-section {
  padding: 0 10px 10px 0;
  margin: .7em 0 1.2em;
  transition: all .1s linear;
  background: #fff;
  border-bottom: 1px solid #dcdbdb;
  overflow: auto;
  overflow-y: hidden;
}
.ass-norm.content .quest-section{
 /* padding-bottom: 2em;    */
}
.kado-checkbox .ant-checkbox-group .ant-checkbox-wrapper,
.kadoRadio.ant-radio-group .ant-radio-wrapper {
  font-family: inherit;
  white-space: normal;
  word-break: break-word;
}
.quest-section .ant-radio-group label, .quest-section .kado-checkbox label {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px;
}
.ant-checkbox+span,
.page-content .ant-radio-wrapper span {
  font-size: 1rem;
  font-weight: 500;
}
.page-footer {
  /* position: sticky;
  position: -webkit-sticky; */
  background: #fff;
  z-index: 9;
  display: block;
  padding: 0px 0px;
  /* border-top: 1px solid #dcdbdb; */
  height: 4rem;
    margin-bottom: 10px;
}
.ass-zoom + .page-footer{
    margin-bottom: 0;
    padding-bottom: 10px;
}
@media (min-width:1366px){
    /* .page-footer {
      padding-bottom: 10px;
    } */
}
.ass-norm.content,
.ass-zoom.content{
    position: relative;
    z-index: 9;
    /* height: calc(100% - (4rem + 20px + 56px)); commented for footer display*/ 
    overflow: auto;
    height: calc(100% - (4rem + 20px));
}
.ass-zoom.content {
    height: calc(100% - 8rem - 20px);
}
.mc-content-wrapper .ass-qst-zoomlist .heading {
  font-size: 1.4rem;
}
.ass-qst-zoomlist>div.quest-section {
  /* background: #fbfbfb; */
  border: 1px solid transparent!important;
  border-radius: 4px;
  padding: 10px 0px 15px ;
  margin-top: 1em;
  margin-bottom: 5px;
}
.rightAligned .ant-radio-group.kadoRadio label{
    max-width: unset;
}
.page-content .header > .heading{
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.ass-ovrview-qst-sec:last-of-type .quest-section:last-of-type{
    margin-bottom: 0px;
    border-color: transparent;
    padding-bottom: 1.2em;
}
.qa-option-wrap .Select-menu-outer{
    position: absolute;
    z-index: 99;
}
.quest-section .b-margin,
.quest-section textarea,
.qa-option-wrap > input{
    margin-bottom: .7em;
}
.qstnre-bottom-help-text{
  padding-left: 20px;
}
.qstnre-bottom-help-text,
.qstnre-top-help-text {
  border-radius: 5px;
  background:#F5FDFF;
  margin:.7em 0;
  padding:.25em .45em;
  margin-right: 20px;
}
.quest-section .Select-placeholder, .quest-section .Select-value, .quest-section .Select-control > div:first-of-type,
.ant-calendar-picker-input.ant-input{
    height: 2.8rem;
}

.slider-clas-spl-pb2em{
  padding-bottom: 2em !important;
}
