

.green-inverted input{
    background-color: transparent;
}
.green-inverted .ant-calendar-picker-clear{
    background: transparent;
}

.sidebarMiniIconcontainer:hover svg path{
    fill:#FFF;
}
.SidebarSecondary .kado-date-picker .green-inverted{
    color: black;
    background-color: transparent;       
}

.purple-inverted{
    color: #5c4795 !important;
}
#SidebarSecondary .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
SidebarSecondary .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
.main-container .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.main-container .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label,
.panel .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.panel .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: #495057;
  font-size: 14px;
  font-weight: 500;
}