/* new */
button{
    cursor: pointer;
}
button.btn-active, button.button-primary:hover {
    background: #44a48a !important;
    border-color: #44a48a !important;
    color: #fff;
}
.btn-wrap.group-btn{
    display: inline-flex;
}
.group-btn.btn-wrap .button:first-child{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.group-btn.btn-wrap .button:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}
.kado-btn,
.button.btn-with-brand{
    border: 1px solid #d0d0d0;
    height: 2.8rem;
    margin: 0;
    padding: .3em .7em;
    border-radius: 5px;
    background: #fff;
    font-weight: 500;
    min-width: 50px;
    font-size: 1rem;
    color: #51af96;
}
.kado-btn{
    height: auto;
}
button.btn-active {
    color: #fff !important;
}
.button.btn-with-brand.button-inactive {
    color: #a3a3a4!important;
    pointer-events: none;
}
.print-pdf-div{
    /* margin-left: auto; */
}
.pdf-dwn-btn, .print-btn {
    cursor: pointer;
    border: none;
    color: #595959;
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 5px;
    background: #F6F6F6;
    width: 2.8rem;
    height: 2.8rem;
    transition: all .3s linear;
}
.pdf-dwn-btn:hover, .print-btn:hover{
    background: #ececec;
} 