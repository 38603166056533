.scale-slider {
    display: flex;
    height: calc(100vh - 25em);
    min-height: 700px;
    padding-top: 80px;
}
.ass-singleview-qst .scale-slider{
    padding-top: 30px;
}
.scale-slider .sliderdata {
    width: 60%;
    max-width: 640px;
    position: relative;
}
.scale-slider .sliderdata ul {
    padding-left: 1em;
    list-style-type: disc;
    top: -3px;
    position: relative;
}
.scale-slider .sliderdata li {
    margin-bottom: 10px;
    line-height: 1.4;
    position: relative;
}
.scale-slider .sliderdata li:before {
    /* content: ''; */
    width: 0.5em;
    height: 0.5em;
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    border-radius: 50%;
    transform: translateY(50%);
    left: -1em;
    top: 0.1em;
}
.scale-slider .slider-userinput {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 45%;
    right: 0%;
    width: 100%;
    z-index: 1;
}
.scale-slider .sliderdata input {
    padding: 0.2em;
    max-width: 4em;
    border-radius: 0;
    border-color: #5d5c5c;
    margin: 0 0.5em;
}
.scale-slider .slider-wrap {
    width: 35%;
    max-height: calc(100% - 0%);
    position: relative;
    margin: 0 auto;
}
.scale-slider .slider-header {
    position: absolute;
    text-align: center;
    width: 60%;
    max-width: 220px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9em;
    line-height: 1.3;
}
.scale-slider .slider-header:first-of-type {
    bottom: 100%;
    transform: translate(-50%, -5px);
}
.scale-slider .rangeslider__handle {
    height: 10px;
    background-color: #eaeaea;
}
.scale-slider .rangeslider.rangeslider-vertical {
    min-height: 90%;
    background-color: #5d5c5c;
    width: 2px;
    border-radius: 2px;
    margin: 0.3em auto;
}
.scale-slider .rangeslider-vertical .rangeslider__labels {
    margin-left: 0px;
    width: 20px;
}
.scale-slider .rangeslider-vertical .rangeslider__labels:after {
    content: '';
    width: 30px;
    height: 16px;
    background: rgb(255, 255, 255);
    display: flex;
    right: 100%;
    bottom: 0px;
    position: absolute;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li {
    color: transparent;
    pointer-events: none;
    margin-left: 16px;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li:before {
    left: -20px;
    transform: translateX(calc(-50% - 5px));
}
.scale-slider .rangeslider-vertical .rangeslider__labels li.Showlabel {
    color: #5d5c5c;
    pointer-events: all;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li.Showlabel:before {
    width: 20px;
}
.scale-slider .rangeslider-vertical .rangeslider__labels li.Showlabel.wide:before {
    width: 35px;
}
.scale-slider .rangeslider .rangeslider__handle {
    height: 18px;
    width: 18px;
    border-radius:50%;
    z-index: 9;
    left: 1px;
    background-color: #027E02;
    transform: translate(-50%, -7px);
}
