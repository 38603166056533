display_table_cell{
    width: 100%;
    display: inline-block;
}
.login_copy_right{
    margin-top: 10px;
}
.login_copy_right div{
    color: #141414;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
}
.login_copy_right a{
    color: #2B5EC2 !important;
    text-decoration: none; 
    border-bottom: none; 
    position: relative;
}