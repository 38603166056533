/* 
.ant-dropdown-menu{
    box-shadow: 0 2px 8px #ddd;
    max-height: 50vh;
    overflow: auto;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title button{
	line-height: 15px;
}
.ant-checkbox + span {
    font-size: 12px;
}
.mc-header.mc-title.mc-title-btn{
    margin-top: -4px;
    float: left;
}
.mc-title-btn .ant-switch{
    height: 30px;
    line-height: 25px;
}
.mc-header.mc-title.mc-title-btn .ant-switch:after{
    top: 5px;
}
.mc-content-div .kado-slider .ant-slider-mark{
    left:2px;
}
.kadoRadio.green.inverted .ant-radio-checked .ant-radio-inner{
    background-color:#fff !important;
}
.mc-profile .profile-dob-div .kado-date-picker.ant-calender-picker div input{
    font-size: 12px;    
    background-color: red;
} */
.ant-checkbox+span,
.kadoRadio.ant-radio-group .ant-radio-wrapper span {
    color: #6b6c6c;
}
.kado-checkbox .ant-checkbox-inner, .kadoRadio .ant-radio-inner {
    min-width: 22px!important;
    min-height: 22px!important;
    border-width: 3px!important;
    border-color: #585859;
}
.kadoRadio .ant-radio-inner:after {
    top: 25%;
    left: 25%;
}
.mc-content-wrapper .rangeslider-horizontal {
    height: 10px;
    border-radius: 10px;
    min-width: 500px;
}
.mc-content-wrapper .rangeslider, .mc-content-wrapper .rangeslider .rangeslider__fill {
    box-shadow: none;
}
.rangeslider-horizontal .rangeslider__fill {
    background-color: #44a48a!important;
}
.rangeslider__labels .rangeslider__label-item {
    font-size: inherit!important;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #44a48a;
    border-color: #44a48a;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after{
    border-color: #44a48a;
}
.kado-checkbox .ant-checkbox-inner:after {
    width: 6.2px;
    height: 12.14285714px;
}
.quest-section input,
.quest-section textarea.md-textarea{  
    border: none !important;
    border: 1px solid #D0D0D0 !important;
    padding: 7px;
    border-radius: 5px;
    min-height: 100px;
  }
  .quest-section input,
  .quest-section textarea {
    background: #fff;
    min-height: auto;
  }
.quest-section textarea{
    max-height: 110px;
    overflow: auto;
}
.ant-checkbox + span{
    position: relative;
    top: -1px;
}
.quest-section .Select-input input{
    border: none !important;
}
.quest-section .Select{
    border: none !important;

}
.quest-section .Select-control{
    border: 1px solid #D0D0D0 !important;
    border-radius: 5px;
}
.quest-section .Select-placeholder,
.quest-section .Select-value,
.quest-section .Select-control > div:first-of-type{
    display: flex;
    align-items: center;
    padding-left: 7px !important;
}
.quest-section .Select-input input{
    padding-left:0;
}
.quest-section.z-top{
    z-index: 99999;
    overflow:visible;
}