input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="time"],
input[type="date"],
input[type="datetime-local"],
input[type="tel"],
input[type="number"],
input[type="search-md"],
input[type="search"],
textarea.md-textarea {
  background-color: transparent;
  height: 2rem;
  letter-spacing: 1px;
  color: #495057;
  font-size: 14px;
  font-weight: bold;
}
input[type="text"]:disabled,
input:focus:not([readonly]),
input[type="text"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
input[type="time"]:focus:not([readonly]),
input[type="date"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="search-md"]:focus:not([readonly]),
input[type="search"]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
  border: none;
  box-shadow: none;
}
input:hover:not([readonly]),
input[type="text"]:hover:not([readonly]),
input[type="password"]:hover:not([readonly]),
input[type="email"]:hover:not([readonly]),
input[type="url"]:hover:not([readonly]),
input[type="time"]:hover:not([readonly]),
input[type="date"]:hover:not([readonly]),
input[type="datetime-local"]:hover:not([readonly]),
input[type="tel"]:hover:not([readonly]),
input[type="number"]:hover:not([readonly]),
input[type="search-md"]:hover:not([readonly]),
input[type="search"]:hover:not([readonly]),
textarea.md-textarea:hover:not([readonly]) {
  border: none;
  box-shadow: none;
}
textarea {
  background: #f9fdfc;
  overflow: auto;
}
/* .header-search-wrapper input[type=text],
.header-search-wrapper input[type=text]:hover{
    border-bottom: none;
} */
#faf-message.ant-input {
  border-bottom: none;
  background-color: #fff;
  border: 1px solid #dddcdc;
}
#faf-message.ant-input:focus,
#faf-message.ant-input:hover {
  border: 1px solid #51af96 !important;
  transition: all 0.1s;
}
