.ant-slider {
	margin: 10px 0px;
	height: 16px;
}
.ant-slider-rail,
.ant-slider-track {
	height: 8px;
	border-radius: 0px;
}
.ant-slider-with-marks {
	margin: 10px 0px 25px 0px;	
}
.ant-slider-handle {
	margin-top: -6px;
    width: 20px;
    height: 20px;
}
.ant-slider-dot{
	display: none;
}
.kado-slider {
	padding: 0px 16px 0px 3px;
}