.diaryHelpWrapper{}
.qn-reminder-div td{
    /* color: #fff;
    background-color: #dddcdc;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    text-align: center;
    padding: 0px 10px 5px 10px;
    border-radius: 10px; */
}
.qn-reminder-div td{
    color: #929292;
    /* background-color: #dddcdc; */
    border-bottom: 1px solid #dddcdc;
    border-right: 1px solid #dddcdc;
    text-align: center;
    padding: 0px 10px 5px 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 1px #dddcdc;
}