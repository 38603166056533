.kado-notify.ant-notification-notice {
	padding: 6px 12px;
}

.kado-notify .ant-notification-notice-btn,
.kado-notify .ant-notification-notice-close {
	display: none;
}

.kado-notify .ant-notification-notice-with-icon .ant-notification-notice-message {
	margin-left: 32px;
}
.kado-notify .ant-notification-notice-icon {
	top: 10px;
}
.kado-notify .ant-notification-notice-icon-info {
	color: #4cb2e1;
}
.kado-notify .ant-notification-notice-icon-error {
	color: #ff4a52;
}
.kado-notify .ant-notification-notice-icon-warning {
	color: #edb00a;
}