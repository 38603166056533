/* .panelPlaceholderWrapper {
  text-align: center;
  padding: 25%;
  background-color: rgba(255, 255, 255, 0);
  min-width: fit-content;
}
.panelPlaceholderWrapper span {
  color: #868e96 !important;
} */
.panelPlaceholderWrapper {
   padding: 40px 25%
  
}
.panelPlaceholder {
  margin: 0.3em auto;
  /* min-width: 280px; */
}
