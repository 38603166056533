.label {
  font-family: Lato;
  letter-spacing: 1.05px;
  color: #424343;
}
.x-large {
  font-size: 24px;
}
.large {
  font-size: 16px;
}
.medium {
  font-size: 14px;
}
.x-medium {
  font-size: 12px;
}
.small {
  font-size: 10px;
}
.x-Small {
  font-size: 9px;
}

.heavy {
  font-weight: 800;
}
.bold {
  font-weight: 600;
}
.light {
  font-weight: 400;
}
.uppercase {
  text-transform: uppercase;
}
